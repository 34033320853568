import { Box, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Button, Heading, Modal } from '../../components/Common'
import { useLocation } from 'react-router-dom'
import Logo from '../../assets/images/Logo.png'
import { RiEditCircleLine } from 'react-icons/ri'
import { MdDownloading } from 'react-icons/md'
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { orderDetailsByIdApiHandler } from '../../Service/salesOrder'

import { toWords } from "number-to-words";

const formatAmountInWords = (amount) => {
  // Convert the number to words and capitalize first letter of each word
  const words = toWords(amount)
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return `INR ${words} Only`;
};

const SInvoiceDetails = () => {
  const { state } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", para: '', head: '', id: '' });

  const dispatch = useDispatch()

  const [purchaseOrder, setPurchaseOrder] = React.useState({})
  const taxableAmount =
  (parseInt(purchaseOrder?.subtotal) || 0) + (parseInt(purchaseOrder?.deliveryCharge) || 0);

// Convert total amount to words
const amountInWords = formatAmountInWords(parseInt(purchaseOrder?.totalAmount) || 0);
const totalCreditAmount = purchaseOrder?.productId?.reduce((sum, item) => 
  sum + Number(item.creditAmount || 0), 0);
  useEffect(() => {
    if (state && state?.id) {
      purchaseOrderData(state.id)
    }
  }, [])



  const purchaseOrderData = async (id) => {
    const res = await orderDetailsByIdApiHandler(id)
    if (res?.status) {
      setPurchaseOrder(res?.data)
    } else {
      setPurchaseOrder({})
    }
  }

  function aggregateTaxes(products) {
    if (!products) return [];

    const taxMap = new Map();

    products.forEach(product => {
      if (product && product.taxName) {
        const taxCalculateValue = parseFloat(product.taxCalculateValue) || 0;
        const taxValue = parseFloat(product.taxValue) || 0;
        const taxExtraValue = parseFloat(product.taxExtraValue) || 0;

        if (taxMap.has(product.taxName)) {
          const existing = taxMap.get(product.taxName);
          taxMap.set(product.taxName, {
            taxCalculateValue: existing.taxCalculateValue + taxCalculateValue,
            taxValue: existing.taxValue + taxValue,
            taxExtraValue: existing.taxExtraValue + taxExtraValue
          });
        } else {
          taxMap.set(product.taxName, {
            taxCalculateValue,
            taxValue,
            taxExtraValue
          });
        }
      }
    });

    return Array.from(taxMap, ([taxName, values]) => ({
      taxName,
      taxCalculateValue: values.taxCalculateValue,
      taxValue: values.taxValue,
      taxExtraValue: values.taxExtraValue
    }));
  }

  const handleDownload = async (order, datas) => {
    const taxableAmount =
    (parseInt(order?.subtotal) || 0) + (parseInt(order?.deliveryCharge) || 0);

  // Convert total amount to words
  const amountInWords = formatAmountInWords(parseInt(order?.totalAmount) || 0);
  const totalCreditAmount = order?.productId?.reduce((sum, item) => 
    sum + Number(item.creditAmount || 0), 0);
   const data = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
              font-size: 12px;
          }
          .invoice-container {
             
              padding: 20px;
              max-width: 800px;
              margin: 0 auto;
          }
          .title {
              text-align: center;
              margin-bottom: 20px;
              font-size: 18px;
              font-weight: bold;
          }
          .header-section {
              display: flex;
              margin-bottom: 20px;
          }
          .left-header {
              flex: 1;
              display: flex;
              flex-direction: column;
          }
          .right-header {
              flex: 1;
              display: flex;
          }
          .company-details {
      display: flex; 
      align-items: center;
      border: 1px solid #000;
      padding: 10px;
      gap: 10px; 
      }
  
      .company-details img {
          max-width: 80px; 
          max-height: 80px; 
      }
  
      .company-details-text {
          flex: 1;
      }
  
          .company-details, .address-box {
              border: 1px solid #000;
              padding: 10px;  
          }
          .heading {
              font-weight: bold;
              margin-bottom: 5px;
          }
          .details-table {
              width: 100%;
              border-collapse: collapse;
          }
          .details-table th, 
          .details-table td {
              border: 1px solid #000;
              padding: 5px;
              text-align: left;
              height: 25px;
          }
          .items-table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
          }
          .items-table th, 
          .items-table td {
              border: 1px solid #000;
              padding: 5px;
              text-align: left;
          }
          .amount-words {
              margin: 10px 0;
              font-weight: bold;
          }
          .tax-table {
              width: 100%;
              border-collapse: collapse;
              margin: 15px 0;
          }
          .tax-table th, 
          .tax-table td {
              border: 1px solid #000;
              padding: 5px;
              text-align: center;
          }
          .declaration {
              margin: 15px 0;
          }
          .declaration ol {
              margin-left: 20px;
          }
          .bank-details {
              margin: 15px 0;
              padding-top: 10px;
              border-top: 1px solid #000;
          }
          .footer {
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
          }
          .footer-left {
              font-weight: bold;
          }
          .footer-right {
              text-align: right;
          }
          .computer-generated {
              text-align: center;
              margin-top: 20px;
              font-style: italic;
          }
      </style>
  </head>
  <body>
      <div class="invoice-container">
          <div class="title">Tax Invoice</div>
          
          <div class="header-section">
              <div class="left-header">
                  <div class="company-details">
                      <img src=${Logo} alt="Company Logo">
                      <div class="company-details-text">
                          <div class="heading">CSD Trading Corporation Pvt Ltd</div>
                          Ashadeep Tower, 1st Floor
                          A.K.Azad Road, Rehabari
                          Guwahati, Kamrup Metropolitan
                          GSTIN/UIN: 18AAKCC9805A1ZQ
                          State Name: Assam, Code: 18
                          E-Mail: rohit@csdtc.shop
                      </div>
                  </div>
                  
                  
                  <div class="address-box">
                      <div class="heading">Consignee (Ship to)</div>
                      ${order?.shippingAddress.address1}
                      ${order?.shippingAddress.address2}
                      ${order?.shippingAddress.city}
                      ${order?.shippingAddress.state}
                      ${order?.shippingAddress.pincode}<br>
                      GSTIN/UIN: ${order?.userId.gstNo}<br>
                      State Name: ${order?.shippingAddress.state}
                  </div>
                  
                  <div class="address-box">
                      <div class="heading">Buyer (Bill to)</div>
                      ${order?.billingAddress.address1}
                      ${order?.billingAddress.address2}
                      ${order?.billingAddress.city}
                      ${order?.billingAddress.state}
                      ${order?.billingAddress.pincode}<br>
            
                      GSTIN/UIN: ${order?.userId.gstNo}<br>
                      State Name: ${order?.billingAddress.state}
                  </div>
              </div>
  
              <div class="right-header">
                  <table class="details-table">
                      <tr>
                          <th>Invoice No. <p>${order?.orderNumber}</p></th>
                          <th>Dated <p>${moment(order?.createdAt).format(
                            "DD-MM-YYYY"
                          )}</p></th>
                      </tr>
                     
                      <tr>
                          <td>Delivery Note</td>
                          <td>Mode/Terms of Payment</td>
                      </tr>
                      <tr>
                          <td>Reference No. & Date.</td>
                          <td>Other References</td>
                      </tr>
                      <tr>
                          <td>Buyer's Order No.</td>
                          <td>Dated</td>
                      </tr>
                      <tr>
                          <td>Dispatch Doc No.</td>
                          <td>Delivery Note Date</td>
                      </tr>
                      <tr>
                          <td>Dispatched through</td>
                          <td>Destination</td>
                      </tr>
                      <tr>
                          <td colspan="2">Terms of Delivery</td>
                      </tr>
                  </table>
              </div>
          </div>
  
          <table class="items-table">
              <tr>
                  <th>Sl No.</th>
                  <th colspan="2">Description of Goods</th>
      
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
              </tr>
              ${order.productId
                .map(
                  (item, index) => `
                  <tr>
                      <td >${index + 1}</td>
                      <td colspan="2">${item.productId.productName}</td>
                      <td>${item.productId.price}</td>
                      <td>${item.quantity}</td>
                      <td>${item.amount}</td>
                  </tr>
              `
                )
                .join("")}
              <tr>
                  <td colspan="5">Subtotal</td>
                  <td>${order?.subtotal}</td>
              </tr> 
            
              <tr>
                  <td colspan="5">FREIGHT CHARGES</td>
                  <td>${order?.deliveryCharge ? order?.deliveryCharge : 0}</td>
              </tr>
              <tr>
                  <td colspan="5">GST</td>
                  <td>${order?.taxValue}</td>
              </tr>
             ${
               order?.creditStatus
                 ? `
                  <tr>
                      <td colspan="5">Credit Amount</td>
                      <td>${totalCreditAmount}</td>
                  </tr>
  `
                 : ""
             }
  
              <tr>
                  <td colspan="5">Discount</td>
                  <td>${order?.discountAmount}</td>
              </tr>
             
              <tr>
                  <td colspan="5"><strong>Total</strong></td>
                  <td><strong>₹ ${order?.totalAmount}</strong></td>
              </tr>
          </table>
  
          <div class="amount-words">
              Amount Chargeable (in words): ${amountInWords}
          </div>
  
          <table class="tax-table">
              <tr>
                  
                  <th>Taxable Value</th>
                  <th>GST Amount</th>
                  
                  <th>Total Tax Amount</th>
              </tr>
              <tr>
                  <td>${taxableAmount}</td>
                  <td>${order?.taxValue}</td>
                  <td>${order?.totalAmount}</td>
              </tr>
          </table>
  
          <div class="declaration">
              <strong>Declaration</strong>
              <ol>
                  <li>The buyer will in every case examine the goods on taking delivery and thereafter will have no claim against the company concerning the nature of quality of the goods.</li>
                  <li>No refund of GST or other taxes of any kind will be made.</li>
                  <li>The company's risk, responsibility and liability ceases on delivery of goods when the same are handed over.</li>
                  <li>Maximum credit period will be of one month and any payment made after a period of one month from the date of purchase will attract simple interest @18% p.a.</li>
              </ol>
          </div>
  
          <div class="bank-details">
              <strong>Company's Bank Details</strong><br>
              Bank Name: ICICI BANK LTD<br>
              A/c No.: 278205500532<br>
              Branch & IFS Code: PALTAN BAZAR & ICIC0002782
          </div>
  
          <div class="footer">
              <div class="footer-left">SUBJECT TO ASSAM JURISDICTION</div>
              <div class="footer-right">
                  for CSD Trading Corporation Pvt Ltd<br><br>
                  Authorised Signatory
              </div>
          </div>
  
          <div class="computer-generated">
              This is a Computer Generated Invoice
          </div>
      </div>
  </body>
  </html>`;

    html2pdf()
      .set({
        filename: datas ? `Bill-${order?.orderNumber}.pdf` : `Bill-${order?.orderNumber}.pdf`,
        margin: 10,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 4, letterRendering: true },
        jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
      })
      .from(data)
      .save()
    purchaseOrderData(purchaseOrder.id)

  }

  console.log('purchaseOrder', aggregateTaxes(purchaseOrder.productId))

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <BackButton />
          <Heading head='Invoice Details' />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'}>
          <Button buttonName='Download' startIcon={<MdDownloading style={{ fontSize: '14px' }} />} onClick={() => {
            handleDownload(purchaseOrder)
          }} />
        </Stack>
      </Stack>
      <div style={{
        marginTop: '50px',
      }} dangerouslySetInnerHTML={{
        __html: `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <style>
                body {
                    font-family: Arial, sans-serif;
        
                    font-size: 12px;
                }
                .invoice-container {
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 20px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                       background: white;
            
                }
                .title {
                    text-align: center;
                    margin-bottom: 20px;
                    font-size: 18px;
                    font-weight: bold;
                }
                .header-section {
                    display: flex;
                    margin-bottom: 20px;
                }
                .left-header {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
                .right-header {
                    flex: 1;
                    display: flex;
                }
                .company-details {
            display: flex; 
            align-items: center;
            border: 1px solid #000;
            padding: 10px;
            gap: 10px; 
            }
        
            .company-details img {
                max-width: 80px; 
                max-height: 80px; 
            }
        
            .company-details-text {
                flex: 1;
            }
        
                .company-details, .address-box {
                    border: 1px solid #000;
                    padding: 10px;  
                }
                .heading {
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                .details-table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .details-table th, 
                .details-table td {
                    border: 1px solid #000;
                    padding: 5px;
                    text-align: left;
                    height: 25px;
                }
                .items-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin: 20px 0;
                }
                .items-table th, 
                .items-table td {
                    border: 1px solid #000;
                    padding: 5px;
                    text-align: left;
                }
                .amount-words {
                    margin: 10px 0;
                    font-weight: bold;
                }
                .tax-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin: 15px 0;
                }
                .tax-table th, 
                .tax-table td {
                    border: 1px solid #000;
                    padding: 5px;
                    text-align: center;
                }
                .declaration {
                    margin: 15px 0;
                }
                .declaration ol {
                    margin-left: 20px;
                }
                .bank-details {
                    margin: 15px 0;
                    padding-top: 10px;
                    border-top: 1px solid #000;
                }
                .footer {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }
                .footer-left {
                    font-weight: bold;
                }
                .footer-right {
                    text-align: right;
                }
                .computer-generated {
                    text-align: center;
                    margin-top: 20px;
                    font-style: italic;
                }
            </style>
        </head>
        <body>
            <div class="invoice-container">
                <div class="title">Tax Invoice</div>
                
                <div class="header-section">
                    <div class="left-header">
                        <div class="company-details">
                            <img src=${Logo} alt="Company Logo">
                            <div class="company-details-text">
                                <div class="heading">CSD Trading Corporation Pvt Ltd</div>
                                Ashadeep Tower, 1st Floor
                                A.K.Azad Road, Rehabari
                                Guwahati, Kamrup Metropolitan
                                GSTIN/UIN: 18AAKCC9805A1ZQ
                                State Name: Assam, Code: 18
                                E-Mail: rohit@csdtc.shop
                            </div>
                        </div>
                        
                        
                        <div class="address-box">
                            <div class="heading">Consignee (Ship to)</div>
                            ${purchaseOrder?.shippingAddress?.address1}
                            ${purchaseOrder?.shippingAddress?.address2}
                            ${purchaseOrder?.shippingAddress?.city}
                            ${purchaseOrder?.shippingAddress?.state}
                            ${purchaseOrder?.shippingAddress?.pincode}<br>
                            GSTIN/UIN: ${purchaseOrder?.userId?.gstNo}<br>
                            State Name: ${purchaseOrder?.shippingAddress?.state}
                        </div>
                        
                        <div class="address-box">
                            <div class="heading">Buyer (Bill to)</div>
                            ${purchaseOrder?.billingAddress?.address1}
                            ${purchaseOrder?.billingAddress?.address2}
                            ${purchaseOrder?.billingAddress?.city}
                            ${purchaseOrder?.billingAddress?.state}
                            ${purchaseOrder?.billingAddress?.pincode}<br>
                  
                            GSTIN/UIN: ${purchaseOrder?.userId?.gstNo}<br>
                            State Name: ${purchaseOrder?.billingAddress?.state}
                        </div>
                    </div>
        
                    <div class="right-header">
                        <table class="details-table">
                            <tr>
                                <th>Invoice No. <p>${purchaseOrder?.orderNumber}</p></th>
                                <th>Dated <p>${moment(purchaseOrder?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}</p></th>
                            </tr>
                           
                            <tr>
                                <td>Delivery Note</td>
                                <td>Mode/Terms of Payment</td>
                            </tr>
                            <tr>
                                <td>Reference No. & Date.</td>
                                <td>Other References</td>
                            </tr>
                            <tr>
                                <td>Buyer's Order No.</td>
                                <td>Dated</td>
                            </tr>
                            <tr>
                                <td>Dispatch Doc No.</td>
                                <td>Delivery Note Date</td>
                            </tr>
                            <tr>
                                <td>Dispatched through</td>
                                <td>Destination</td>
                            </tr>
                            <tr>
                                <td colspan="2">Terms of Delivery</td>
                            </tr>
                        </table>
                    </div>
                </div>
        
                <table class="items-table">
                    <tr>
                        <th>Sl No.</th>
                        <th colspan="2">Description of Goods</th>
            
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Amount</th>
                    </tr>
                    ${purchaseOrder?.productId?.map(
                        (item, index) => `
                        <tr>
                            <td >${index + 1}</td>
                            <td colspan="2">${item.productId.productName}</td>
                            <td>${item.productId.price}</td>
                            <td>${item.quantity}</td>
                            <td>${item.amount}</td>
                        </tr>
                    `
                      )
                      .join("")}
                    <tr>
                        <td colspan="5">Subtotal</td>
                        <td>${purchaseOrder?.subtotal}</td>
                    </tr> 
                  
                    <tr>
                        <td colspan="5">FREIGHT CHARGES</td>
                        <td>${purchaseOrder?.deliveryCharge ? purchaseOrder?.deliveryCharge : 0}</td>
                    </tr>
                    <tr>
                        <td colspan="5">GST</td>
                        <td>${purchaseOrder?.taxValue}</td>
                    </tr>
                   ${
                     purchaseOrder?.creditStatus
                       ? `
                        <tr>
                            <td colspan="5">Credit Amount</td>
                            <td>${totalCreditAmount}</td>
                        </tr>
        `
                       : ""
                   }
        
                    <tr>
                        <td colspan="5">Discount</td>
                        <td>${purchaseOrder?.discountAmount}</td>
                    </tr>
                   
                    <tr>
                        <td colspan="5"><strong>Total</strong></td>
                        <td><strong>₹ ${purchaseOrder?.totalAmount}</strong></td>
                    </tr>
                </table>
        
                <div class="amount-words">
                    Amount Chargeable (in words): ${amountInWords}
                </div>
        
                <table class="tax-table">
                    <tr>
                        
                        <th>Taxable Value</th>
                        <th>GST Amount</th>
                        
                        <th>Total Tax Amount</th>
                    </tr>
                    <tr>
                        <td>${taxableAmount}</td>
                        <td>${purchaseOrder?.taxValue}</td>
                        <td>${purchaseOrder?.totalAmount}</td>
                    </tr>
                </table>
        
                <div class="declaration">
                    <strong>Declaration</strong>
                    <ol>
                        <li>The buyer will in every case examine the goods on taking delivery and thereafter will have no claim against the company concerning the nature of quality of the goods.</li>
                        <li>No refund of GST or other taxes of any kind will be made.</li>
                        <li>The company's risk, responsibility and liability ceases on delivery of goods when the same are handed over.</li>
                        <li>Maximum credit period will be of one month and any payment made after a period of one month from the date of purchase will attract simple interest @18% p.a.</li>
                    </ol>
                </div>
        
                <div class="bank-details">
                    <strong>Company's Bank Details</strong><br>
                    Bank Name: ICICI BANK LTD<br>
                    A/c No.: 278205500532<br>
                    Branch & IFS Code: PALTAN BAZAR & ICIC0002782
                </div>
        
                <div class="footer">
                    <div class="footer-left">SUBJECT TO ASSAM JURISDICTION</div>
                    <div class="footer-right">
                        for CSD Trading Corporation Pvt Ltd<br><br>
                        Authorised Signatory
                    </div>
                </div>
        
                <div class="computer-generated">
                    This is a Computer Generated Invoice
                </div>
            </div>
        </body>
        </html>
  ` }} />
    </>
  )
}

export default SInvoiceDetails