import React from 'react';
import { 
  Drawer, 
  Grid, 
  Typography, 
  Divider, 
  Stack, 
  IconButton, 
  Box 
} from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import {Button, Select} from '../../components/Common';
import { TicketstatusFilter } from '../../helper/Helper';

const TicketFilter = ({ openDrawer, toggleDrawer, formik }) => {
  const drawerWidth = 250;

  const handleCloseClick = (event) => {
    event.stopPropagation();
    toggleDrawer();
  };

  return (
    <Drawer 
      anchor="right" 
      open={openDrawer} 
      onClose={toggleDrawer}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
      }}
    >
      <Box height={'100%'}>
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={'20px'}>Filter</Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseClick}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} marginY={'5px'}>
            <Divider sx={{ borderColor: "gray.main" }} />
          </Grid>

          <Grid item xs={12} marginY={2}>
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Select
                  labelinput='Status'
                  selectHead='None' // Placeholder for select head
                  name='status'
                  formik={formik}
                  onChange={(e) => formik.setFieldValue('status', e.target.value)}
                  selectList={TicketstatusFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Stack flexDirection={'row'} gap={1} mt={1}>
        <Button 
          buttonName='Clear' 
          color='white' 
          style={{ width: '100%' }} 
          size='small' 
          onClick={() => {
            formik.resetForm();
            toggleDrawer();
            formik.handleSubmit();
          }} 
        />
        <Button 
          buttonName='Filter' 
          style={{ width: '100%' }} 
          size='small' 
          onClick={formik.handleSubmit} 
        />
      </Stack>
    </Drawer>
  );
};

export default TicketFilter;
