import { deleteRequest, get, post, put } from "../web.request";

export const SalesOrderListData = async (query) => {
  return await get(`/salesOrderList${query}`);
};

export const salesOrderStatusUpdate = (id, payload) => {
  return put(`/orderStatusChange/${id}`, payload);
};

export const orderDetailsByIdApiHandler = (id) => {
  return get(`/salesOrderDetail/${id}`);
};
export const OrderDocs = (query) => {
  return get(`/OrderDocs${query}`);
};
