import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  LinearProgress,
  TextField,
  InputAdornment,
  Dialog,
} from '@mui/material';
import { CloseIcon } from '../../../helper/Icons';
import { Button } from '../../../components/Common';
import { IoSearchSharp } from "react-icons/io5";
import { useFormik } from 'formik';
import { purchaseProductListData, purchaseUpdateActiveQuantity } from '../../../Service/Purchase.service'

const ManageAchievedQuantityModal = ({ handleClose, data, modalOpen, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [productList, setProductList] = useState([]);
  
  const getProductList = useCallback(async () => {
    const res = await purchaseProductListData(modalOpen.id);
    if (res?.status) {
      setProductList(res?.data);
      console.log(res.data)
    } else {
      setProductList([]);
    }
  }, [modalOpen.id]);
  const updateActiveQuantity = useCallback(async (payload) => {
    const res = await purchaseUpdateActiveQuantity(payload);
    if (res?.status) {
      getProductList()
    } else {
      getProductList();
    }
  }, [modalOpen.id]);
  
  useEffect(() => {
    if (modalOpen?.id) {
      getProductList();
    }
  }, [getProductList]);
  


  // Initialize formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      products: productList?.map(product => ({
        id: product.id,
        achievedQuantity: product.achievedQuantity,
        orderQuantity: product.orderQuantity,
        name: product.name,
        activeQuantity: 0,
      })),
    },
    onSubmit: (values) => {
      console.log("values",values);
      updateActiveQuantity(values)
    },
  });
  
  const filteredProducts = useMemo(
    () =>
      formik.values.products.filter(product =>
        product.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      ),
    [formik.values.products, searchTerm]
  );
  

  const handleActiveQuantityChange = (productId, value) => {
    const numValue = parseInt(value) || 0; 
    const product = formik.values.products.find(p => p.id === productId); 

    // Don't allow more than order quantity
    if (product && numValue + product.achievedQuantity > product.orderQuantity || numValue < 0) {
      return;
    }

    const updatedProducts = formik.values.products.map(product =>
      product.id === productId
        ? {
            ...product,
            activeQuantity: numValue, 
          }
        : product
    );

    formik.setFieldValue('products', updatedProducts);
  };

  const getProgressValue = (achieved, order) => (order > 0 ? (achieved / order) * 100 : 0);



  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id="ManageAchievedQuantityModal">
        <Typography variant="h6">Manage Achieved Quantity</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: '100vh', overflow: 'hidden' }}>
        {isLoading ? (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, mt: 1 }}>
              <TextField
                fullWidth
                size="small"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IoSearchSharp />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Achieved Qty.</TableCell>
                    <TableCell align="right">Order Qty.</TableCell>
                    <TableCell align="right">Progress</TableCell>
                    <TableCell align="right">Add Active Qty.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {console.log("filteredProducts",filteredProducts)}
                  {filteredProducts.map((product) => {
                    const currentProduct = formik.values.products.find(p => p.id === product.id) || {};
                    return (
                      <TableRow
                        key={product.id}
                        sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                      >
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">
                          <Typography color="primary">
                            {product.achievedQuantity}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography color="text.secondary">
                            {product.orderQuantity}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Box sx={{ width: '100%', mr: 1 }}>
                        
                            <LinearProgress
                              variant="determinate"
                              value={getProgressValue(product.achievedQuantity, product.orderQuantity)}
                              sx={{ height: 8, borderRadius: 2 }}
                            />
                          </Box>
                          <Typography variant="caption" color="text.secondary">
                            {Math.round(getProgressValue(product.achievedQuantity, product.orderQuantity))}%
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            value={currentProduct.activeQuantity || 0} // Default to 0
                            onChange={(e) => handleActiveQuantityChange(product.id, e.target.value)}
                            type="number"
                            sx={{
                              width: '80px',
                              '& input': { textAlign: 'right', padding: '8px' }
                            }}
                           
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Box sx={{ flex: 1, pl: 2 }}>
          <Typography variant="caption" color="text.secondary">
            {filteredProducts.length} products found
          </Typography>
        </Box>
        <Button buttonName="Cancel" size="small" color="white" onClick={handleClose} />
        <Button
          buttonName="Save"
          size="small"
          onClick={formik.handleSubmit}
          disabled={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ManageAchievedQuantityModal;
