import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../../components/Common";
import { salesOrderStatusUpdate } from "../../../Service/salesOrder";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";

function OrderStatusChnages({ callBackHandler, handleClose, modalOpen, data }) {
  console.log("callBackHandler", callBackHandler);
  console.log("handleClose", handleClose);
  console.log("modalOpen", modalOpen);
  console.log("data", data);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const { id, name, orderId } = modalOpen;

  const agreeHandler = async (id) => {
    try {
      setLoading(true);
      if (id === 4 || id === 8 || id === 14) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message: "You can't change this status",
          })
        );
        handleClose();
        return; 
      }
      const payload = {
        orderStatus: id,
        note: note,
      };
      console.log("payload", payload);
      const { data, message, status } = await salesOrderStatusUpdate(
        orderId,
        payload
      );
      if (status) {
        dispatch(
          notificationSuccess({
            show: true,
            type: "success",
            message: message || "Success",
          })
        );
        handleClose();
        callBackHandler();
      } else {
        dispatch(
          notificationSuccess({
            show: true,
            type: "error",
            message: message || "Something went wrong.",
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Status Change
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography
            fontSize={"18px"}
          >{`Are you sure want to change status ${name}?`}</Typography>
        </Box>
        <Grid container spacing={2} width={{ sm: "500px" }} paddingY={1}>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Notes"}
              name="note"
              onChange={(event) => setNote(event.target.value)}
              maxLength={200}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Save"
          size="small"
          disabled={loading || !note}
          onClick={() => agreeHandler(id)}
        />
      </DialogActions>
    </>
  );
}

export default OrderStatusChnages;
