import { deleteRequest, get, post, put } from "../web.request";

export const purchaseOrderListData = async (query) => {
  return await get(`/purchaseOrderList${query}`);
}
export const purchaseProductListData = async (id) => {
  return await get(`/purchaseProductListData/${id}`);
}
export const purchaseProductMailData = async (id) => {
  return await get(`/purchaseProductMailData/${id}`);
}
export const sendMail = async (data) => {
  return await post(`/sendMail`,data);
}

export const purchaseOrderCreate = async (data) => {
  return await post(`/addPurchaseOrder`, data);
}

export const purchaseOrderUpdate = async (id, data) => {
  return await put(`/updatePurchaseOrder/${id}`, data);
}
export const purchaseUpdateActiveQuantity = async (data) => {
  return await put(`/updateActiveQuantity`, data);
}

export const purchaseOrderDetail = async (id) => {
  return await get(`/purchaseOrderDetail/${id}`);
}

export const purchaseOrderPayment = async (data) => {
  return await post(`/addPayment`, data);
}