import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, FileUpload, Input } from "../../components/Common";

const AddBrand = ({ handleClose, modalOpen, formik, files, setFiles }) => {
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {isEdit ? "Edit" : isView ? "View" : "Add"} Brand
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: "500px" }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Name"
              disabled={isView}
              formik={formik}
              name="categoryName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "13px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Image
            </Typography>
            <FileUpload files={files} setFiles={setFiles} singleImage={true} />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Company"
              disabled={isView}
              formik={formik}
              name="company"
              // options={companyOptions} // An array of company options
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isView ? (
          ""
        ) : (
          <>
            <Button
              buttonName="Cancel"
              size="small"
              color="white"
              onClick={handleClose}
            />
            <Button
              buttonName={`${isEdit ? "Save" : "Add"}`}
              size="small"
              onClick={formik.handleSubmit}
            />
          </>
        )}
      </DialogActions>
    </>
  );
};

export default AddBrand;
