import { Grid, Skeleton, Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Heading } from '../../components/Common'
import { Box } from '@mui/system'
import { TransporterDetail } from '../../Service/Transporter.service'
import { useLocation } from 'react-router-dom'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

const TransporterDetails = () => {
  const { state } = useLocation()
  const [TransporterData, setTransporterData] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  const getTransporterData = async (id) => {
    const response = await TransporterDetail(id)
    if (response && response.data) {
      console.log(response.data)
      setTransporterData(response.data)
      setIsLoading(false)
    }
    else {
      setTransporterData({})
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (state && state?.id) {
      getTransporterData(state?.id)
    } else {
      setIsLoading(false)
    }
  }, [])

  const handleDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderContentRow = (label, value) => (
    isLoading ? (
      <Skeleton animation="wave" variant="text" width="100%" height={30} />
    ) : (
      <Stack flexDirection="row">
        <Typography fontWeight={500} fontSize="18px" color="#999" width="200px">
          {label}
        </Typography>
        <Typography fontWeight={500} fontSize="18px">
          {value}
        </Typography>
      </Stack>
    )
  );

  const renderDocuments = () => (
    isLoading ? (
      <Skeleton animation="wave" variant="rectangular" width="100%" height={100} />
    ) : (
      <Stack spacing={2}>
        <Typography fontWeight={500} fontSize="18px" color="#999">
          Documents
        </Typography>
        {TransporterData?.docs?.map((doc, index) => (
          <Stack 
            key={index} 
            direction="row" 
            spacing={2} 
            alignItems="center"
            sx={{
              padding: '10px',
              border: '1px solid #eee',
              borderRadius: '4px'
            }}
          >
            <Typography flex={1}>{doc.name}</Typography>
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={() => handleDownload(doc.downloadUrl, doc.name)}
            >
              Download
            </Button>
          </Stack>
        ))}
      </Stack>
    )
  );

  return (
    <>
      <Stack flexDirection="row" justifyContent="flex-start" gap={1} alignItems="center" mb={2}>
        <BackButton />
        <Heading head="Transporter Details" />
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="card_container" p={4}>
            {renderContentRow("Username", TransporterData?.username)}
            {renderContentRow("Email", TransporterData?.userEmail)}
            {renderContentRow("Phone Number", TransporterData?.transporterNumber)}
            {renderContentRow("Vehicle Number", TransporterData?.vehicleNumber)}
            {renderContentRow("Vehicle Type", TransporterData?.vehicleType)}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="card_container" p={4}>
            {renderDocuments()}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TransporterDetails;