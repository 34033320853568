import { deleteRequest, get, post, put } from "../web.request";


export const userDropdown = async () => {
  return await get(`/userDropdown`);
}

export const userList = async (query) => {
  return await get(`/userList${query}`);
}

export const changeUserStatus = async (id) => {
  return await put(`/statusChangeUser/${id}`);
}

export const deleteUser = async (id) => {
  return await deleteRequest(`/deleteUser/${id}`);
}

export const userDetail = async (id) => {
  return await get(`/userDetail/${id}`);
}

export const userUpdate = async (id, data) => {
  return await put(`/updateUser/${id}`, data);
}


export const userAddressUpdate = async (id, data) => {
  return await put(`/userAddressUpdate/${id}`, data);
}


export const userDropdownDiscount = async () => {
  return await get(`/userdiscountDropdown`);
}

export const changeUserB2BStatus = async (id) => {
  return await put(`/b2bStatusChangeUser/${id}`);
}