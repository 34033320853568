import { RxDashboard } from "react-icons/rx";
import { BiBox } from "react-icons/bi";
import {
  MdKeyboardArrowRight,
  MdOutlineCategory,
  MdOutlineManageAccounts,
  MdOutlinePayments,
} from "react-icons/md";
import {
  TbBuildingSkyscraper,
  TbFileInvoice,
  TbNotebook,
  TbTicket,
} from "react-icons/tb";
import { PiWarehouse } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi";
import { GrLocation } from "react-icons/gr";
import { FiBox } from "react-icons/fi";
import { SlNotebook } from "react-icons/sl";
import { LuShoppingCart } from "react-icons/lu";
import { FaRegAddressBook } from "react-icons/fa";

export const SidebarList = [
  {
    name: "Dashboard",
    icon: <RxDashboard style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <RxDashboard style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/dashboard",
    SUBMENU: false,
  },
  {
    name: "Admin Mgt.",
    icon: (
      <MdOutlineManageAccounts style={{ color: "#000", fontSize: "22px" }} />
    ),
    activeIcon: (
      <MdOutlineManageAccounts style={{ color: "#fff", fontSize: "22px" }} />
    ),
    path: "/admins",
    SUBMENU: false,
  },
  {
    name: "Users Mgt.",
    icon: <HiOutlineUsers style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <HiOutlineUsers style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/users",
    SUBMENU: false,
  },
  {
    name: "Company",
    icon: <TbBuildingSkyscraper style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: (
      <TbBuildingSkyscraper style={{ color: "#fff", fontSize: "22px" }} />
    ),
    path: "/company",
    SUBMENU: false,
  },
  {
    name: "Warehouse",
    icon: <PiWarehouse style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <PiWarehouse style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/warehouse",
    SUBMENU: false,
  },
  {
    name: "Category",
    icon: <MdOutlineCategory style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: (
      <MdOutlineCategory style={{ color: "#fff", fontSize: "22px" }} />
    ),
    path: "/category",
    SUBMENU: false,
  },
  {
    name: "Product",
    icon: <BiBox style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <BiBox style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/product",
    SUBMENU: false,
  },
  {
    name: "Coupons",
    icon: <TbTicket style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <TbTicket style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/coupon",
    SUBMENU: false,
  },
  // {
  //     name: 'Order',
  //     icon: <FiBox style={{ color: '#000', fontSize: '22px' }} />,
  //     activeIcon: <FiBox style={{ color: '#fff', fontSize: '22px' }} />,
  //     path: '/purchase-order'
  // },
  {
    name: "Inventory",
    icon: <FiBox style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <FiBox style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/inventory",
  },
  {
    name: "Payment",
    icon: <MdOutlinePayments style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: (
      <MdOutlinePayments style={{ color: "#fff", fontSize: "22px" }} />
    ),
    path: "/payment",
  },
  {
    name: "Appointment",
    icon: <FaRegAddressBook style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: (
      <FaRegAddressBook style={{ color: "#fff", fontSize: "22px" }} />
    ),
    path: "/appointment",
  },
  {
    name: "Purchase",
    icon: <TbNotebook style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <TbNotebook style={{ color: "#fff", fontSize: "22px" }} />,
    SUBMENU: true,
    subPurchaseMenus: [
      {
        name: "Order",
        icon: <FiBox style={{ color: "#000", fontSize: "20px" }} />,
        activeIcon: <FiBox style={{ color: "#fff", fontSize: "20px" }} />,
        path: "/purchase-order",
      },
    ],
  },
  {
    name: "Sales",
    icon: <TbNotebook style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <TbNotebook style={{ color: "#fff", fontSize: "22px" }} />,
    SUBMENU: true,
    subSalesMenus: [
      {
        name: "Cart",
        icon: <LuShoppingCart style={{ color: "#000", fontSize: "20px" }} />,
        activeIcon: (
          <LuShoppingCart style={{ color: "#fff", fontSize: "20px" }} />
        ),
        path: "/sales-cart",
      },
      {
        name: "Order",
        icon: <FiBox style={{ color: "#000", fontSize: "20px" }} />,
        activeIcon: <FiBox style={{ color: "#fff", fontSize: "20px" }} />,
        path: "/sales-order",
      },
      {
        name: "Invoice",
        icon: <TbFileInvoice style={{ color: "#000", fontSize: "20px" }} />,
        activeIcon: (
          <TbFileInvoice style={{ color: "#fff", fontSize: "20px" }} />
        ),
        path: "/sales-invoice",
      },
    ],
  },
  {
    name: "Location",
    icon: <GrLocation style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <GrLocation style={{ color: "#fff", fontSize: "22px" }} />,
    SUBMENU: true,
    subMenus: [
      {
        name: "Country",
        icon: (
          <MdKeyboardArrowRight style={{ color: "#000", fontSize: "20px" }} />
        ),
        activeIcon: (
          <MdKeyboardArrowRight style={{ color: "#fff", fontSize: "20px" }} />
        ),
        path: "/country",
      },
      {
        name: "State",
        icon: (
          <MdKeyboardArrowRight style={{ color: "#000", fontSize: "20px" }} />
        ),
        activeIcon: (
          <MdKeyboardArrowRight style={{ color: "#fff", fontSize: "20px" }} />
        ),
        path: "/state",
      },
      {
        name: "City",
        icon: (
          <MdKeyboardArrowRight style={{ color: "#000", fontSize: "20px" }} />
        ),
        activeIcon: (
          <MdKeyboardArrowRight style={{ color: "#fff", fontSize: "20px" }} />
        ),
        path: "/city",
      },
    ],
  },
];

export const categoryList = [
  { name: "Category 1", value: "Category 1" },
  { name: "Category 2", value: "Category 2" },
];

export const subCategoryList = [
  { name: "Sub Category 1", value: "Sub Category 1" },
  { name: "Sub Category 2", value: "Sub Category 2" },
];

export const productType = [
  { name: "Product Type 1", value: "Product Type 1" },
  { name: "Product Type 2", value: "Product Type 2" },
];

export const status = [
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "Inactive" },
];

export const statusFilter = [
  { name: "Active", id: "1" },
  { name: "Inactive", id: "0" },
  { name: "Deleted", id: "2" },
];
export const AppoinmentstatusFilter = [
  { name: "Open", id: "0" },
  { name: "Book", id: "1" },
  { name: "Close", id: "2" },
  { name: "Cancel", id: "3" },
  { name: "Complete", id: "4" },
];

export const saleOrderStatus = [
  {
    name: "Pending",
    id: 1,
  },
  {
    name: "Confirm",
    id: 2,
  },
  {
    name: "Transport",
    id: 3,
  },
  {
    name: "Delivered",
    id: 4,
  },
  {
    name: "Cancel",
    id: 5,
  },
  {
    name: "Refund",
    id: 6,
  },
  {
    name: "Return requested",
    id: 7,
  },
  {
    name: "Returned",
    id: 8,
  },
  {
    name: "Return started",
    id: 9,
  },
  {
    name: "Return Declined",
    id: 10,
  },
  {
    name: "Replace requested",
    id: 11,
  },
  {
    name: "Replace started",
    id: 12,
  },
  {
    name: "Replace Declined",
    id: 13,
  },
  {
    name: "Replace Completed",
    id: 14,
  },
];



export const appintmentTypeList = [
  {
    name: "Bulk Order",
    value: 1,
    id: "1",
  },
  { name: "Expert Consultation", value: 2, id: "2" },
];

export const discountTypeList = [
  { name: "Flat", value: "Flat", id: "0" },
  { name: "Percentage", value: "Percentage", id: "1" },
];

export const couponTypeList = [
  { name: "User", value: "User", id: "1" },
  { name: "Public", value: "Public", id: "0" },
];


export const TicketstatusFilter = [
  { name: "Active", id: "active" },
  { name: "Resolved", id: "resolved" },
  { name: "Cancelled", id: "cancelled" },
];