import { get, post, put } from "../web.request";

export const getRaiseTicketDetails = async (id) => {
  return await get(`/raiseTicketDetail/${id}`);
}

export const getRaiseTicketList = async (query) => {
  return await get(`/raiseTicketList${query}`);
}

export const RaiseTicketStatusChange = async (id, data) => {
  return await put(`/statusChangeRaiseTicket/${id}`, data);
}
