import React, { useEffect, useState } from 'react'
import {Button, Heading, MenuButton, Select} from '../../components/Common'
import { Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { IoAdd, IoEyeOutline } from 'react-icons/io5'
import {Pagination, Search } from '../../components/Common/Table'
import TableRowsLoader  from '../../components/Common/Loader/Skeleton'
import { useNavigate } from 'react-router-dom'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from 'react-icons/md'
import TicketFilter from './RaiseTicketFilter'
import { VscFilter } from 'react-icons/vsc'
import { filterTicketInitialValues } from '../../helper/initialValues'
import { useFormik } from 'formik'
import { getRaiseTicketList, RaiseTicketStatusChange } from '../../Service/RaiseTicket.service'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import moment from 'moment'
import { notificationSuccess } from '../../state/Action/NotificationAction'
import { useDispatch } from 'react-redux'

export const RaiseTicketPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortBy, setSortBy] = useState(false);
  const [shortKey, setShortKey] = useState('name');
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [totalData, setTotalData] = useState(0)
  const [sortDate, setSortDate] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(30);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const formik = useFormik({
    initialValues: filterTicketInitialValues,
    onSubmit: async (value) => {
      setOpenDrawer(false)
      getTicketData()
    },
  });

  const getTicketData = async () => {
    console.log(formik.values.status)
    let query = `?search=${search}&status=${formik.values.status}&page=${page + 1}&perPageSize=${rowsPerPage}&sortFlag=${sortBy}&orderBy=${shortKey}`

    const res = await getRaiseTicketList(query);
    if (res?.status) {
      setTicketData(res.data)
      setTotalData(res.total)
    } else {
      setTicketData([])
    }
  }
  const handleTicketSubmission = () => {
    getTicketData(); // Refresh ticket list after submission
};
  useEffect(() => {
    getTicketData();
  }, [search, page, rowsPerPage, sortBy, shortKey]);

  const StatusChange = async (id, newStatus) => {
    const payload = {
      status: newStatus,
    }

    const res = await RaiseTicketStatusChange(id, payload);
    if (res?.status) {
      getTicketData()
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Status updated successfully'
      }))
    }
  }

  const truncateText = (text, maxLength) => {
    return text && text !== "" ? text.length > maxLength ? text.slice(0, maxLength) + '...' : text : "-"
  };

  const handleSortClick = (name, by) => {
    switch (name) {
      case 'date':
        setSortBy(!by)
        setSortDate((current) => !current)
        setShortKey('createdAt')
        break;

      default:
        setSortBy(!sortDate)
        setSortDate(false)
        setShortKey('name')
        break;
    }
  };

  return (
    <div>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
        <Heading head='Raise Ticket' />
      </Stack>
      <TableContainer component={Paper} >

        <Stack p={'12px'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />

          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              buttonName='Filter'
              onClick={toggleDrawer}
              startIcon={<VscFilter />}
              color='white'
              size='small'
            />
          </Stack>

          <TicketFilter
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            formik={formik}
          />
        </Stack>

        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow >
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell style={{ cursor: 'pointer' }} onClick={() => handleSortClick('date', sortDate)}>Date
                {sortDate ?
                  <FaArrowUp style={{ color: '#adadad' }} />
                  :
                  <FaArrowDown style={{ color: '#adadad' }} />
                }
              </TableCell>
              <TableCell>Ticket No.</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketData && ticketData.length > 0 && ticketData.map((data, index) => (
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }} onClick={() => navigate('/view-ticket', {
                state: {
                  id: data.id
                }
              })} key={index}>
                <TableCell className='table-hover' title={data && data?.name}>{truncateText(data?.name, maxCharacters)}</TableCell>
                <TableCell className='table-hover' title={data.email}>{truncateText(data?.email, maxCharacters)}</TableCell>
                <TableCell className='table-hover' title={data.subject}>{truncateText(data?.subject, maxCharacters)}</TableCell>
                <TableCell>{data && data.createdAt ? moment(data.createdAt).format('DD-MM-YYYY') : "-"}</TableCell>
                <TableCell>{data && data.ticketissue_no ? data.ticketissue_no : "-"}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  {data && data.status === 'active' ? (
                    <Select
                      name='status'
                      value={data.status}
                      onChange={(e) => {
                        const newStatus = e.target.value;
                        StatusChange(data.id, newStatus);
                      }}
                      selectList={[
                        { id: 'active', name: 'Active' },
                        { id: 'resolved', name: 'Resolved' },
                        { id: 'cancelled', name: 'Cancelled' }
                      ]}
                    />
                  ) : data && data.status === 'cancelled' ? (
                    <Chip label='Cancelled' style={{ backgroundColor: 'red', color: 'white' }} />
                  ) : data && data.status === 'resolved' ? (
                    <Chip label='Resolved' style={{ backgroundColor: 'green', color: 'white' }} />
                  ) : (
                    <Chip label='Active' style={{ backgroundColor: 'yellow', color: 'black' }} />
                  )}
                </TableCell>
                <TableCell onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger className='chatDropDown'>
                      <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '24px' }} />} width='35px' height='38px' />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content className='chatDropDownContent' align='end'>
                      <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/view-ticket', {
                        state: {
                          id: data.id
                        }
                      })}>
                        <Stack flexDirection={'row'} gap={1} alignItems={'center'}><IoEyeOutline style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>View</Box></Stack>
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </TableCell>
              </TableRow>
            ))}
            
            {ticketData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="7"
                >
                  No records to display
                </TableCell>
              </TableRow>

            ) : (
              ticketData && ticketData.length === 0 ? (<TableRowsLoader rowsNum={1} />) : ("")
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        ticketData != null && (
          <Pagination totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />)
      }
    </div >
  )
}