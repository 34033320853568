import React, { useState } from "react";
import { MenuItem, Select, Typography } from "@mui/material";

export default function CommonSelect(props) {
  const {
    selectHead,
    selectList,
    labelinput,
    value,
    style,
    className,
    onChange,
    formik,
    disabled,
  } = props;
  console.log(props)

  return (
    <>
      {labelinput && (
        <Typography
          fontSize={{ xs: "13px", sm: "16px" }}
          fontWeight={500}
          mb={"2px"}
        >
          {labelinput}
        </Typography>
      )}
      <Select
        value={value ? value : formik && formik.values[props.name]}
        onChange={onChange}
        labelId="demo-simple-select-error-label"
        id="demo-simple-select-error"
        displayEmpty
        fullWidth
        sx={style}
        className={className}
        error={
          formik && formik.touched[props.name] && formik.errors[props.name]
        }
        disabled={disabled}
      >
        {selectHead && <MenuItem value="">{selectHead}</MenuItem>}
        {selectList.map((data, i) => (
          <MenuItem value={data.id} name={data.name || data?.roleName} key={i}>
            {data.name || data?.roleName}
          </MenuItem>
        ))}
      </Select>
      {formik && formik.touched[props.name] && formik.errors[props.name] && (
        <Typography fontSize={"12px"} color={"#D32F2F"}>
          {formik.errors[props.name]}
        </Typography>
      )}
    </>
  );
}
