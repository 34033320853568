import { deleteRequest, get, post, put } from "../web.request";

export const TransporterList = async (query) => {
  return await get(`/transporters${query}`)
}

export const TransporterCreate = async (data) => {
  return await post(`/transporters`, data);
}

export const TransporterUpdate = async (id, data) => {
  return await put(`/transporters/${id}`, data);
}

export const TransporterDetail = async (id) => {
  return await get(`/transporters/${id}`);
}

